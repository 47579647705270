import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import NavPills from "components/NavPills/NavPills.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import {
    Card,
} from "@material-ui/core";

import IconDict from "variables/IconDict.js"
import Notes from "views/Components/Notes.js";
import Issues from "views/Components/Issues.js";
import GeneralReportTemplate from "views/Components/GeneralReportTemplate.js";

import { NotificationContext, ExperimentContext } from "views/Components/Context.js";
import styles from "assets/jss/material-dashboard-pro-react/views/experimentStyle.js";
const useStyles = makeStyles(styles);

export default function ExplainabilityStage(props) {
    const classes = useStyles();

    const {
        allNotes: [allNotes, setNotesHelper],
        allIssues: [allIssues, setIssuesHelper],
        stages: [stages, setStages],
        projectID: projectID
    } = React.useContext(ExperimentContext)

    let tmpTabs = [
        {
            tabButton: "Explainability",
            tabContent: (
                <Card className={classes.card}>
                    <CardHeader icon>
                        <CardIcon color="quSandBox">
                            <IconDict.Explainability />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>
                            Explainability
                        </h4>
                    </CardHeader>
                    <CardBody>
                        {/* {projectID === '0d371a9d315447d3af8e9c8adaac23e6 + 8' ?
                            <iframe
                                id="explainability"
                                title="explainability"
                                src="https://test.quantuniversity.com/"
                                width="100%"
                                height="600px"
                            ></iframe> : */}
                        {projectID === '0d371a9d315447d3af8e9c8adaac23e6 + 8' ?
                            <iframe
                                id="explainability"
                                title="explainability"
                                src="https://test.quantuniversity.com/"
                                width="100%"
                                height="600px"
                            ></iframe> :
                            <GridContainer justify="center">
                                <Button
                                    color="info"
                                    size="lg"
                                    href={`${process.env.PUBLIC_URL}/admin/QuToolBox/Explain`}
                                    target="_blank"
                                >
                                    Browse Tools
                                </Button>
                            </GridContainer>
                        }

                    </CardBody>
                </Card>
            ),
            tabIcon: IconDict['Explainability']
        }
    ]
    if (props.config.reports) {
        tmpTabs.push(
            {
                tabButton: "Reports",
                tabIcon: IconDict['Reports'],
                tabContent: (<GeneralReportTemplate
                    templateId={
                        props.config.template
                            ? props.config.template
                            : ""
                    }
                    projectId={projectID}
                    stageId={stages.hasOwnProperty('Explainability') ? stages['Explainability'].PK : ""}
                    notes={allNotes.hasOwnProperty("Explainability")
                        ? allNotes.Explainability
                        : []}
                    stageType="Explainability"
                />)
            }
        )
    }
    if (props.config.notes) {
        tmpTabs.push(
            {
                tabButton: "Notes",
                tabIcon: IconDict['Notes'],
                tabContent: (<Notes
                    notes={allNotes.hasOwnProperty("Explainability")
                        ? allNotes.Explainability
                        : []}
                    setNotes={setNotesHelper}
                    stageType="Explainability"
                />),
            }
        )
    }
    if (props.config.issues) {
        tmpTabs.push(
            {
                tabButton: "Issues",
                tabIcon: IconDict['Issues'],
                tabContent: (
                    <Issues
                        issues={allIssues.hasOwnProperty("Explainability")
                            ? allIssues.Explainability
                            : []}
                        setIssues={setIssuesHelper}
                        stageType="Explainability"
                    />
                ),
            })
    }

    const tabs = tmpTabs
    return <NavPills
        color="quSandBoxColor"
        alignCenter
        tabs={tabs}
    />
}