import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import NavPills from "components/NavPills/NavPills.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import Accordion from "components/Accordion/Accordion.js";
import {
    Card,
    CardActionArea,
} from "@material-ui/core";

import IconDict from "variables/IconDict.js"
import Notes from "views/Components/Notes.js";
import Issues from "views/Components/Issues.js";
import GeneralReportTemplate from "views/Components/GeneralReportTemplate.js";

import { NotificationContext, ExperimentContext } from "views/Components/Context.js";
import styles from "assets/jss/material-dashboard-pro-react/views/experimentStyle.js";
const useStyles = makeStyles(styles);

export default function EnvironmentStage(props) {
    const classes = useStyles();

    const [stageActive, setStageActive] = React.useState(0);
    const [ignoreKeys, setIgnoreKeys] = React.useState([
        "encoded",
        "entities",
        "stages",
        "id",
        "_id",
        "version",
        "status",
        "output_number",
        "input_number",
        "access_role",
    ]);

    const drawArrow = () => {
        return (
            <div
                style={{
                    margin: "auto",
                    position: "absolute",
                    left: "48%",
                }}
            >
                <svg width="70" height="200">
                    <defs>
                        <marker
                            id="markerArrow1"
                            markerWidth="13"
                            markerHeight="13"
                            refX="2"
                            refY="6"
                            orient="auto"
                        >
                            <path d="M2,2 L2,11 L10,6 L2,2" />
                        </marker>
                    </defs>
                    <path
                        d="M10,0 L10,22"
                        style={{
                            stroke: "#000000",
                            markerEnd: "url(#markerArrow1)",
                        }}
                    />
                </svg>
            </div>
        );
    };


    const printJson = (json) => {
        // console.log(json)
        return Object.keys(json).map(
            (key, index) =>
                // console.log(key)
                // {
                !ignoreKeys.includes(key) &&
                (json[key] instanceof Object ? (
                    <div key={index}>
                        <div>{printJson(json[key])}</div>
                    </div>
                ) : (
                    !!json[key] && (
                        <div key={index} style={{ overflowWrap: "anywhere" }}>
                            <span
                                style={{
                                    fontWeight: "bold",
                                }}
                            >
                                {key}:
                            </span>{" "}
                            <span>{json[key].toString()}</span>
                        </div>
                    )
                ))
            // }
        );
    };

    const {
        projectID: projectID,  
        experimentJson: [experimentJson, setExperimentJson],
        allNotes: [allNotes, setNotesHelper],
        allIssues: [allIssues, setIssuesHelper],
        stages: [stages, setStages]
    } = React.useContext(ExperimentContext)
    let tmpTabs = [
        {
            tabButton: "Environment",
            tabContent: (
                <Card className={classes.card}>
                    <CardHeader icon>
                        <CardIcon color="quSandBox">
                            <IconDict.Environment />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Environment</h4>
                    </CardHeader>
                    <CardBody>
                        <GridContainer justify="center">
                            <GridItem xs={12}>
                                {
                                    stages.hasOwnProperty('Environment') && stages['Environment'].hasOwnProperty('Image') &&
                                    <img
                                        src={stages['Environment'].Image}
                                        style={{ maxWidth: "100%" }}
                                        alt=""
                                    />
                                }
                            </GridItem>
                            <Button
                                color="info"
                                size="lg"
                                href="https://modelrisk.qusandbox.com/#!/pipelines"
                                target="_blank"
                            >
                                Run on Model Studio
                            </Button>
                        </GridContainer>
                    </CardBody>
                </Card>
            ),
            tabIcon: IconDict['Environment']
        }
    ]
    tmpTabs.push({
        tabButton: "Pipeline",
        tabIcon: IconDict['Pipeline'],
        tabContent: (
            <Card className={classes.card}>
                <CardHeader icon>
                    <CardIcon color="quSandBox">
                        <IconDict.Pipeline />
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}>Pipeline</h4>
                </CardHeader>
                <CardBody>
                    <GridContainer>
                        {/* {experimentJson} */}
                        <GridItem md={5}>
                            <GridContainer justify="center">
                                <GridItem
                                    style={{
                                        margin: "15px",
                                        position: "relative",
                                    }}
                                    xs={12}
                                >
                                    <Card
                                        className={classes.summaryTab}
                                        style={{
                                            border: "solid 1px black",
                                            textAlign: "center",
                                            paddingBottom: "10px",
                                            paddingTop: "10px",
                                        }}
                                    >
                                        <CardHeader>
                                            <b>Start</b>
                                        </CardHeader>
                                    </Card>
                                    {drawArrow()}
                                </GridItem>
                                {experimentJson.hasOwnProperty("stages") &&
                                    experimentJson.stages.map((stage, index) => (
                                        <GridItem
                                            style={{
                                                margin: "15px",
                                                position: "relative",
                                            }}
                                            xs={12}
                                            key={index}
                                        >
                                            <Card
                                                className={classes.summaryTab}
                                                style={{
                                                    border: "solid 1px black",
                                                    textAlign: "center",
                                                }}
                                            >
                                                <CardActionArea
                                                    style={{
                                                        paddingBottom: "20px",
                                                        paddingTop: "10px",
                                                    }}
                                                    onClick={() => {
                                                        setStageActive(index);
                                                    }}
                                                >
                                                    <CardHeader
                                                        style={{ paddingBottom: "0px" }}
                                                    >
                                                        <b>{stage[0]["name"]}</b>
                                                    </CardHeader>
                                                    {stage[0]["entities"].map(
                                                        (entity, i) => (
                                                            <div key={i}>
                                                                <hr />
                                                                <div
                                                                    className={classes.summaryTab}
                                                                >
                                                                    {entity["name"]}
                                                                </div>
                                                            </div>
                                                        )
                                                    )}
                                                </CardActionArea>
                                            </Card>
                                            {drawArrow()}
                                        </GridItem>
                                    ))}
                                <GridItem
                                    style={{
                                        margin: "15px",
                                        position: "relative",
                                    }}
                                    xs={12}
                                >
                                    <Card
                                        className={classes.summaryTab}
                                        style={{
                                            border: "solid 1px black",
                                            textAlign: "center",
                                            paddingBottom: "10px",
                                            paddingTop: "10px",
                                        }}
                                    >
                                        <CardHeader>
                                            <b>Stop</b>
                                        </CardHeader>
                                    </Card>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                        <GridItem md={7} xs={12}>
                            <div
                                style={{ margin: "15px", position: "relative" }}
                            >
                                {printJson(experimentJson)}
                                <p
                                    className={classes.summaryTab}
                                    style={{ paddingTop: "25px" }}
                                >
                                    <b>
                                        Environment:{" "}
                                        {experimentJson.hasOwnProperty("stages") &&
                                            experimentJson.stages[stageActive][0]
                                                .name}
                                    </b>
                                </p>
                                {printJson(experimentJson.stages[stageActive])}
                                <Accordion
                                    active={-1}
                                    collapses={
                                        experimentJson.hasOwnProperty("stages") &&
                                        experimentJson.stages[stageActive][0][
                                            "entities"
                                        ].map((entity, index) => {
                                            return {
                                                title: (
                                                    <div className={classes.summaryTab}>
                                                        <b>{entity["name"]}</b>
                                                    </div>
                                                ),
                                                content: <div>{printJson(entity)}</div>,
                                            };
                                        })
                                    }
                                />
                                <p className={classes.summaryTab}>
                                    <b>Model: </b>
                                    <a
                                        color="info"
                                        size="lg"
                                        // onClick={() => {
                                        //   setTileActive(5);
                                        // }}
                                        href={`?stage=Model`}
                                        style={{ textDecoration: "underline" }}
                                    >
                                        View model board
                                    </a>
                                </p>
                                <p className={classes.summaryTab}>
                                    <b>Data: </b>
                                    <a
                                        color="info"
                                        size="lg"
                                        // onClick={() => {
                                        //   setTileActive(4);
                                        // }}
                                        href={`?stage=Data`}
                                        style={{ textDecoration: "underline" }}
                                    >
                                        View data board
                                    </a>
                                </p>
                            </div>
                        </GridItem>
                    </GridContainer>
                </CardBody>
            </Card>
        ),
    })
    if (props.config.reports) {
        tmpTabs.push(
            {
                tabButton: "Reports",
                tabIcon: IconDict['Reports'],
                tabContent: (<GeneralReportTemplate
                    templateId={
                        props.config.template
                            ? props.config.template
                            : ""
                    }
                    projectId={projectID}
                    stageId={stages.hasOwnProperty('Environment') ? stages['Environment'].PK : ""}
                    notes={allNotes.hasOwnProperty("Environment")
                        ? allNotes.Environment
                        : []}
                    stageType="Environment"
                />)
            }
        )
    }
    if (props.config.notes) {
        tmpTabs.push(
            {
                tabButton: "Notes",
                tabIcon: IconDict['Notes'],
                tabContent: (<Notes
                    notes={allNotes.hasOwnProperty("Environment")
                        ? allNotes.Environment
                        : []}
                    setNotes={setNotesHelper}
                    stageType="Environment"
                />),
            }
        )
    }
    if (props.config.issues) {
        tmpTabs.push(
            {
                tabButton: "Issues",
                tabIcon: IconDict['Issues'],
                tabContent: (
                    <Issues
                        issues={allIssues.hasOwnProperty("Environment")
                            ? allIssues.Environment
                            : []}
                        setIssues={setIssuesHelper}
                        stageType="Environment"
                    />
                ),
            })
    }

    const tabs = tmpTabs
    return <NavPills
        color="quSandBoxColor"
        alignCenter
        tabs={tabs}
    />
}