import React, { useEffect } from "react";
import {
  Route,
  Switch,
  Link,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
import Table from "components/Table/Table.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import ReactEmbedGist from "react-embed-gist";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import DeleteIcon from "@material-ui/icons/Delete";
import Heading from "components/Heading/Heading.js";
// @material-ui/icons
// import ContentCopy from "@material-ui/icons/ContentCopy";
import {
  InputLabel,
  Dialog,
  TextField,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  CircularProgress,
} from "@material-ui/core";
// Amplify components
import { Auth } from "aws-amplify";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import SweetAlert from "react-bootstrap-sweetalert";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import ExperimentDetails from "views/Pages/user/ExperimentDetails";
import { NotificationContext, ProjectContext } from "views/Components/Context.js";

import defaultProjectStructure from "variables/defaultProjectStructure.js"
import ProjectStructureEditor from "views/Components/ProjectStructureEditor.js"

const useStyles = makeStyles(styles);
const usesweetAlertStyle = makeStyles(sweetAlertStyle);

export default function Experiments(props) {
  const PERMISSION = ["read", "clone", "write", "audit", "own"];
  const classes = useStyles();
  const history = useHistory();
  const sweetAlertClasses = usesweetAlertStyle();
  const images = require.context("assets/img", true);
  const [user, setUser] = React.useState();
  const [allExperiments, setAllExperiments] = React.useState([]);
  // const [permanentExperiments, setPermanentExperiments] = React.useState([]);
  const [userType, setUserType] = React.useState("");
  const [modal, setModal] = React.useState(false);
  const [invitedUserEmail, setInvitedUserEmail] = React.useState("");
  //   const [isEdit, setIsEdit] = React.useState(false);
  // const [filter, setFilter] = React.useState(false);

  // const {projectID , projectVersion} = props..par;
  const [name, setName] = React.useState("");
  const [version, setVersion] = React.useState("1.0.0");
  const [brief, setBrief] = React.useState("");
  const [description, setDescription] = React.useState();
  const [gistLink, setGistLink] = React.useState();
  const [colabLink, setColabLink] = React.useState();
  const [project, setProject] = React.useState({});
  const { showNotification } = React.useContext(NotificationContext);
  const [projectName, setProjectName] = React.useState("");
  const [project_Version, setProject_Version] = React.useState("1.0.0");
  const [projectBrief, setProjectBrief] = React.useState("");

  const [generalChanged, setGeneralChanged] = React.useState(false);

  const [projectDescription, setProjectDescription] = React.useState([]);
  const [projectGistLink, setProjectGistLink] = React.useState([]);
  const [projectColabLink, setProjectColabLink] = React.useState([]);
  const [projectJson, setProjectJson] = React.useState();
  const [projectTemplate, setProjectTemplate] = React.useState(defaultProjectStructure);

  // const [projectUsers, setProjectUsers] = React.useState([]);
  // const [editBriefModal, setEditBriefModal] = React.useState(false);
  const [editProjectState, setEditProjectState] = React.useState(0);
  // const [editConfigModal, setEditConfigModal] = React.useState(false);
  const [codeModal, setCodeModal] = React.useState(false);
  const [alert, setAlert] = React.useState(null);
  const { path, url } = useRouteMatch();
  // const [notification, setNotification] = React.useState(false);
  // const [operationStatus, setOperationStatus] = React.useState("success");
  // const [notificationMessage, setNotificationMessage] = React.useState("");
  const { projectID } = props.match.params;
  const [expLoading, setExpLoading] = React.useState(true);
  const [projectLoading, setProjectLoading] = React.useState(true);
  const [inviteUsersModal, setInviteUsersModal] = React.useState(false);
  const [invitedUsers, setInvitedUsers] = React.useState([]);
  const [invitePermission, setInvitePermission] = React.useState("read");
  const [change, setChange] = React.useState(false);
  // const [fetched, setFetched] = React.useState(false);
  // useRouteMatch(
  //   "/admin/projects/experiments/:projectID/:projectVersion"
  // ).params;
  const [showOwn, setShowOwn] = React.useState(false);
  // const [searchValue, setSearchValue] = React.useState("");

  const [projectContextValues, setProjectContextValues] = React.useState({}); 

  // const [structure, setStructure] = React.useState(defaultProjectStructure)

  useEffect(() => {
    setProjectLoading(true);
    if (user) {
      let token = user.signInUserSession.accessToken.jwtToken;
      fetch(
        process.env.REACT_APP_BACK_END_URL +
        `/project/${projectID}?type=project`,
        {
          method: "GET",
          mode: "cors",
          credentials: "omit",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      ).then(
        function (res) {
          res.json().then(function (data) {
            setProject(data.Items[0]);
            // console.log(data.Items)
            setProjectName(data.Items[0].projectName);
            setProjectBrief(data.Items[0].projectBrief);
            setProject_Version(data.Items[0].projectVersion);
            setProjectDescription(data.Items[0].projectDescription);
            setProjectGistLink(data.Items[0].projectGistLink);
            setProjectColabLink(data.Items[0].projectColabLink);
            setProjectJson(data.Items[0].json);
            // setProjectTemplate(data.Items[0].projectTemplate);
            if (data.Items[0].projectTemplate) {
              setProjectTemplate(JSON.parse(data.Items[0].projectTemplate))
              setProjectContextValues({projectTemplate:JSON.parse(data.Items[0].projectTemplate)})
            } else {
              setProjectContextValues({projectTemplate:defaultProjectStructure})
            }
            setProjectLoading(false);
          });
        },
        function (err) {
          console.log(err);
        }
      );
    }
    // }
  }, [user]);

  useEffect(() => {
    // console.log("params");
    // console.log(props.match.params);
    // console.log("location");
    // console.log(props.location.state);
    Auth.currentAuthenticatedUser()
      .then(function (user) {
        setUser(user);
        setUserType(
          user.signInUserSession.accessToken.payload["cognito:groups"][0]
        );
        if (user.signInUserSession.accessToken.payload["cognito:groups"][0] === 'admin') {
          setShowOwn(true)
        }
      })
      .catch((err) => {
        // console.log(err);
        if (err === "not authenticated") {
          history.push("/auth/login");
        }
      });
    // setAllApis(listOfApis);
  }, []);

  useEffect(() => {
    setExpLoading(true);
    if (user) {
      let token = user.signInUserSession.accessToken.jwtToken;
      fetch(process.env.REACT_APP_BACK_END_URL + `/project/${projectID}`, {
        method: "GET",
        mode: "cors",
        credentials: "omit",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }).then(
        function (res) {
          res.json().then(function (data) {
            // console.log(data.Items);
            setAllExperiments(data.Items);
            // setPermanentExperiments(data.Items);
            setExpLoading(false);
          });
        },
        function (err) {
          console.log(err);
        }
      );
    }
  }, [user]);

  //   const setNewApiList = (newList) => {
  //     if (newList.length == 0) {
  //       setAllProjects(permanentApis);
  //     } else {
  //       setAllProjects(newList);
  //     }
  //   };

  const deleteAlert = (projectData) => {
    // console.log(projectData);
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => handleDeleteExperiment(projectData)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={
          sweetAlertClasses.button + " " + sweetAlertClasses.info
        }
        cancelBtnCssClass={
          sweetAlertClasses.button + " " + sweetAlertClasses.info
        }
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
      >
        You will not be able to recover this Project!
      </SweetAlert>
    );
  };

  const cloneAlert = (experimentData) => {
    // console.log(experimentData);
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => handleCloneExperiment(experimentData)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={
          sweetAlertClasses.button + " " + sweetAlertClasses.info
        }
        cancelBtnCssClass={
          sweetAlertClasses.button + " " + sweetAlertClasses.info
        }
        confirmBtnText="Yes, clone it!"
        cancelBtnText="Cancel"
        showCancel
      >
        You will create a new Experiment with _copy as suffix!
      </SweetAlert>
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const handleCodeModal = () => {
    setCodeModal(true);
  };
  const closeCodeModal = () => {
    setCodeModal(false);
  };

  const handleDeleteExperiment = (experimentData) => {
    let token = user.signInUserSession.accessToken.jwtToken;
    fetch(
      process.env.REACT_APP_BACK_END_URL +
      "/experiment/" +
      experimentData.SK.split("#")[1],
      {
        method: "DELETE",
        mode: "cors",
        credentials: "omit",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    )
      .then(function (res) {
        return res.json();
      })
      .then(function () {
        // TODO add info bar
        let data = allExperiments.filter(
          (experiment) => experiment.SK !== experimentData.SK
        );
        setAllExperiments(data);
        showNotification("Experiment deleted successfully.", "quSandBoxColor");
      })
      .catch((error) => {
        showNotification("Delete failed.", "danger");
        console.log(error);
      });
    hideAlert();
  };

  const handleCloneExperiment = (experimentData) => {
    let token = user.signInUserSession.accessToken.jwtToken;
    fetch(
      process.env.REACT_APP_BACK_END_URL +
      "/experiment/" +
      experimentData.SK.split("#")[1],
      {
        method: "POST",
        mode: "cors",
        credentials: "omit",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    )
      .then(function (res) {
        return res.json();
      })
      .then((data) => {
        // window.location.reload();
        if (data.hasOwnProperty("Items")) {
          setAllExperiments((prevState) => [...prevState, data.Items[0]]);
          showNotification("Experiment cloned successfully.", "quSandBoxColor");
        } else {
          showNotification("Clone failed.", "danger");
        }
        return data;
      })
      .catch((error) => {
        showNotification("Clone failed.", "danger");
        console.log(error);
      });
    hideAlert();
  };

  const handleInviteUser = (userName, permission) => {
    let token = user.signInUserSession.accessToken.jwtToken;
    fetch(
      process.env.REACT_APP_BACK_END_URL +
      `/user/${userName}/project/${projectID}?permission=${permission}`,
      {
        // /project/${projectID}`, {
        method: "PUT",
        mode: "cors",
        credentials: "omit",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    )
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        showNotification("User access edited successfully.", "quSandBoxColor");
        setChange(!change);
      })
      .catch((error) => {
        console.log(error);
        showNotification("Project share failed", "danger");
      });
  };

  const handleAccessRevoke = (userName) => {
    let token = user.signInUserSession.accessToken.jwtToken;
    fetch(
      process.env.REACT_APP_BACK_END_URL +
      `/user/${userName}/project/${projectID}`,
      {
        method: "DELETE",
        mode: "cors",
        credentials: "omit",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    )
      .then(function (res) {
        showNotification("User access revoked successfully.", "quSandBoxColor");
      })
      .catch((error) => {
        console.log(error);
        showNotification("Access revoke failed", "danger");
      });
  };

  const handleInviteUserWithEmail = () => {
    // console.log(invitedUserEmail);
    // console.log(invitePermission);
    let token = user.signInUserSession.accessToken.jwtToken;
    fetch(
      process.env.REACT_APP_BACK_END_URL + `/user?info=${invitedUserEmail}`,
      {
        method: "GET",
        mode: "cors",
        credentials: "omit",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    )
      .then(function (res) {
        return res.json();
      })
      .then((data) => {
        // console.log(data);
        if (data.Users.length === 1) {
          const userName = data.Users[0].Username;
          handleInviteUser(userName, invitePermission);
          let newData = [...invitedUsers];
          newData.push({
            id: userName,
            email: invitedUserEmail,
            permission: invitePermission,
            name: data.Users[0].Attributes.find(
              (item) => item.Name === "given_name"
            ).Value,
          });
          setInvitedUsers(newData);
          setChange(!change);
        } else {
          showNotification("Please check the email id.", "danger");
        }
      })
      .then(() => {
        setInvitePermission("");
        setInvitedUserEmail("");
      })
      .catch((error) => {
        console.log(error);
        showNotification("Project share failed", "danger");
      });
  };

  const createExperiment = () => {
    setColabLink(project.projectColabLink)
    setGistLink(project.projectGistLink)
    setModal(true);
  };
  const handleClose = () => {
    setModal(false);
  };

  const editProject = () => {
    // setEditBriefModal(true);
    setEditProjectState(1)
  };
  const editProjectStructure = () => {
    // setEditBriefModal(true);
    setEditProjectState(2)
  };

  // const editProjectConfiguration = () => {
  //   setEditConfigModal(true);
  // };

  const handlePermissionChange = () => {
    setInvitedUsers(
      invitedUsers.map((user) => {
        user["actions"] =
          user.permission === "own" ? (
            user.permission
          ) : (
            <FormControl
              margin="dense"
              fullWidth
              className={classes.selectFormControl}
            >
              <InputLabel
                htmlFor="simple-select"
                className={classes.selectLabel}
              >
                Permission
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={user.permission}
                onChange={(event) => {
                  let newData = [...invitedUsers];
                  newData.find((o, i) => {
                    if (o.email === user.email) {
                      if (event.target.value === "no") {
                        handleAccessRevoke(o.id);
                        newData.splice(i, 1);
                      } else {
                        handleInviteUser(o.id, event.target.value);
                        o.permission = event.target.value;
                      }
                      return true;
                    }
                    return false;
                  });
                  setInvitedUsers(newData);
                  // setChange(!change);
                }}
                inputProps={{
                  name: "simpleSelect",
                  id: "simple-select",
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Permission
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="no"
                >
                  Revoke Access
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="read"
                >
                  Read
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="clone"
                >
                  Clone
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="write"
                >
                  Write
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="audit"
                >
                  Audit
                </MenuItem>
              </Select>
            </FormControl>
          );
        return user;
      })
    );
  };

  useEffect(() => {
    handlePermissionChange();
  }, [change]);

  const inviteUsers = () => {
    if (invitedUsers.length === 0) {
      let token = user.signInUserSession.accessToken.jwtToken;
      fetch(
        process.env.REACT_APP_BACK_END_URL + `/project/${projectID}?type=user`,
        {
          method: "GET",
          mode: "cors",
          credentials: "omit",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      )
        .then((res) => {
          res.json().then((data) => {
            setInvitedUsers(
              data.users.map((user) => {
                let name = user.userInfo.find((item) => item.Name === "given_name")
                return {
                  id: user.userInfo.find((item) => item.Name === "sub").Value,
                  email: user.userInfo.find((item) => item.Name === "email")
                    .Value,
                  name: name !== undefined ? name.Value : "",
                  permission: user.projectPermission,
                };
              })
            );
            setChange(!change);
          });
        })
        // .then(() => {
        //   console.log(invitedUsers);
        //   handlePermissionChange();
        // })
        .catch((error) => {
          console.log(error);
        });

      // /project/-id-?type=user
    }

    setInviteUsersModal(true);
  };

  const handleInviteUsersModalClose = () => {
    setInviteUsersModal(false);
  };

  // const handleEditProjectConfigModalClose = () => {
  //   setEditConfigModal(false);
  // };
  // const handleEditBriefModalClose = () => {
  //   setEditBriefModal(false);
  // };

  const handleEditModalClose = () => {
    setEditProjectState(0);
  };
  // const handleTags = (regularTags) => {
  //   setProjectUsers(regularTags);
  // };

  // const showNotification = (message, status) => {
  //   setOperationStatus(status);
  //   setNotificationMessage(message);
  //   setNotification(true);
  //   setTimeout(function () {
  //     setNotification(false);
  //   }, 6000);
  // };

  const handleCreateExperiment = () => {
    handleClose();
    let token = user.signInUserSession.accessToken.jwtToken;

    fetch(process.env.REACT_APP_BACK_END_URL + `/project/${projectID}`, {
      method: "POST",
      mode: "cors",
      credentials: "omit",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        experimentBrief: brief,
        experimentDescription: description,
        experimentGistLink: gistLink,
        experimentColabLink: colabLink,
        experimentVersion: version,
        experimentName: name,
        json: projectJson,
        experimentTemplate: projectTemplate,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.hasOwnProperty("Items")) {
          setAllExperiments((prevState) => [...prevState, data.Items[0]]);
          showNotification(
            "New Experiment created successfully.",
            "quSandBoxColor"
          );
        } else {
          showNotification("Experiment creation failed.", "danger");
        }
        return data;
      })
      .catch((error) => {
        showNotification("Experiment creation failed.", "danger");
        // handleClose();
        console.log(error);
      });
  };

  // const handleEditProjectConfiguration = () => {
  //   handleEditProjectConfigModalClose();
  //   let token = user.signInUserSession.accessToken.jwtToken;
  //   // console.log(process.env.REACT_APP_BACK_END_URL + `/project/${projectID}`);
  //   fetch(process.env.REACT_APP_BACK_END_URL + `/project/${projectID}`, {
  //     method: "PUT",
  //     mode: "cors",
  //     credentials: "omit",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: token,
  //     },
  //     body: JSON.stringify({
  //       projectDescription: projectDescription,
  //       projectGistLink: projectGistLink,
  //       json: projectJson,
  //     }),
  //   })
  //     .then(function (res) {
  //       return res.json();
  //     })
  //     .then(function (data) {
  //       hideAlert();
  //       let newProject = {
  //         projectGistLink: projectGistLink,
  //         json: projectJson,
  //       };
  //       setProject({ ...project, ...newProject });
  //       showNotification("Project updated successfully.", "quSandBoxColor");
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       showNotification("Update failed.", "danger");
  //     });
  // };

  const handleEditProjectBrief = () => {
    handleEditModalClose();
    let token = user.signInUserSession.accessToken.jwtToken;
    let url = process.env.REACT_APP_BACK_END_URL + `/project/${projectID}`
    if (generalChanged) {
      url = url + '?type=base'
    }
    fetch(
      url,
      {
        method: "PUT",
        mode: "cors",
        credentials: "omit",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          projectBrief: projectBrief,
          projectVersion: project_Version,
          projectName: projectName,
          projectDescription: projectDescription,
          projectGistLink: projectGistLink,
          projectColabLink: projectColabLink,
          projectTemplate: JSON.stringify(projectTemplate),
          json: projectJson,
        }),
      }
    )
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        hideAlert();
        let newProject = {
          projectBrief: projectBrief,
          projectVersion: project_Version,
          projectName: projectName,
          projectDescription: projectDescription,
          projectGistLink: projectGistLink,
          projectColabLink: projectColabLink,
          projectTemplate: JSON.stringify(projectTemplate),
          json: projectJson,
        };
        setProject({ ...project, ...newProject });
        showNotification("Project updated successfully.", "quSandBoxColor");
      })
      .catch((error) => {
        showNotification("Project updation failed.", "danger");
        console.log(error);
      });
  };

  // const searchInputValue = (e) => {
  //   setSearchValue(e);
  //   if (e == "") {
  //     setAllExperiments(permanentExperiments);
  //     return;
  //   }
  //   var newList = permanentExperiments.filter(
  //     (experiment) =>
  //       experiment.ExperimentName.toLowerCase().includes(e.toLowerCase()) ||
  //       (experiment.ExperimentBrief &&
  //         experiment.ExperimentBrief.toLowerCase().includes(e.toLowerCase()))
  //   );

  //   setAllExperiments(newList);
  // };

  return (
    <ProjectContext.Provider value={projectContextValues}>
      {alert}
      {/* <Snackbar
        place="br"
        color={operationStatus}
        icon={AddAlert}
        message={notificationMessage}
        open={notification}
        closeNotification={() => setNotification(false)}
        close
      /> */}
      <Dialog
        open={inviteUsersModal}
        onClose={handleInviteUsersModalClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Invite Users</DialogTitle>
        <DialogContent>
          <GridContainer justify="center">
            <GridItem xs={12} sm={4}>
              <TextField
                margin="dense"
                id="adduser"
                label="Add New User"
                type="text"
                onChange={(event) => {
                  setInvitedUserEmail(event.target.value);
                }}
                value={invitedUserEmail}
                fullWidth
              />
            </GridItem>
            <GridItem xs={12} sm={4}>
              <FormControl
                margin="dense"
                fullWidth
                className={classes.selectFormControl}
              >
                <InputLabel
                  htmlFor="simple-select"
                  className={classes.selectLabel}
                >
                  Permissions
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={invitePermission}
                  onChange={(event) => {
                    setInvitePermission(event.target.value);
                  }}
                  inputProps={{
                    name: "simpleSelect",
                    id: "simple-select",
                  }}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Permissions
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="read"
                  >
                    Read
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="clone"
                  >
                    Clone
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="write"
                  >
                    Write
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="audit"
                  >
                    Audit
                  </MenuItem>
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={4}>
              <Button
                color="quSandBoxColor"
                onClick={handleInviteUserWithEmail}
              >
                Add User
              </Button>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12}>
              <Table
                tableHead={["#", "Email", "Name", "Permissions"]}
                tableData={invitedUsers.map((item, id) => [
                  id + 1,
                  item.email,
                  item.name,
                  item.actions,
                ])}
                style={{ position: "fixed" }}
              />
            </GridItem>
          </GridContainer>
          {/* <ReactTable
            columns={[
              {
                Header: "Email",
                accessor: "email",
              },
              {
                Header: "Name",
                accessor: "name",
              },
              {
                Header: "Permissions",
                accessor: "actions",
              },
            ]}
            data={invitedUsers}
          /> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleInviteUsersModalClose} color="quSandBoxColor">
            Close
          </Button>
          {/* <Button
            onClick={handleEditProjectConfiguration}
            color="quSandBoxColor"
          >
            Submit
          </Button> */}
        </DialogActions>
      </Dialog>
      <Dialog
        open={codeModal}
        onClose={closeCodeModal}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="xl"
      >
        <DialogTitle id="form-dialog-title">Github Gist</DialogTitle>
        <DialogContent>
          {project.projectGistLink && (
            <GridItem xs={12} sm={12} md={12}>
              <ReactEmbedGist
                contentClass={classes.gistclass}
                gist={(project.projectGistLink && project.projectGistLink.includes("gist.github.com/")) ? project.projectGistLink.split("gist.github.com/")[1] : project.projectGistLink}
              />
            </GridItem>
          )}
        </DialogContent>
      </Dialog>
      {/* <Dialog
        open={editConfigModal}
        onClose={handleEditProjectConfigModalClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <div style={{ fontSize: "larger" }}>Edit Project</div>
          <div>Project: {project.projectName}</div>
        </DialogTitle>
        <DialogContent>
          
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleEditProjectConfigModalClose}
            color="quSandBoxColor"
          >
            Cancel
          </Button>
          <Button
            onClick={handleEditProjectConfiguration}
            color="quSandBoxColor"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog> */}
      <Dialog
        open={editProjectState !== 0}
        onClose={handleEditModalClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Edit Project</DialogTitle>
        {editProjectState == 1 && <>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Name"
              type="text"
              onChange={(event) => {
                setGeneralChanged(true);
                setProjectName(event.target.value);
              }}
              value={projectName}
              fullWidth
              required
            />
            <TextField
              margin="dense"
              id="version"
              label="Version"
              type="text"
              onChange={(event) => {
                setGeneralChanged(true);
                setProject_Version(event.target.value);
              }}
              value={project_Version}
              fullWidth
            />
            <TextField
              margin="dense"
              id="brief"
              label="Brief Description"
              type="text"
              onChange={(event) => {
                setGeneralChanged(true);
                setProjectBrief(event.target.value);
              }}
              value={projectBrief}
              fullWidth
              multiline={true}
              rows={3}
            />
            <TextField
              margin="dense"
              id="description"
              label="Detailed desciption for this project"
              type="text"
              onChange={(event) => {
                setProjectDescription(event.target.value);
              }}
              value={projectDescription}
              fullWidth
              multiline={true}
              rows={3}
            />
            <TextField
              margin="dense"
              id="gistLink"
              label="Link to git gist for this project"
              type="text"
              onChange={(event) => {
                setProjectGistLink(event.target.value);
              }}
              value={projectGistLink}
              fullWidth
            />
            <TextField
              margin="dense"
              id="colabLink"
              label="Link to colab for this project"
              type="text"
              onChange={(event) => {
                setProjectColabLink(event.target.value);
              }}
              value={projectColabLink}
              fullWidth
            />
            <CustomInput
              labelText="Custom key json"
              id="custom_key_json"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                multiline: true,
                rows: 5,
                onChange: (event) => {
                  // console.log(event.target.value)
                  setProjectJson(event.target.value);
                },
                value: projectJson,
              }}
            />
          </DialogContent>
        </>}
        {editProjectState == 2 &&
          <DialogContent>
            <ProjectStructureEditor
              structure={projectTemplate}
              setStructure={setProjectTemplate}
              projectId={projectID}
            ></ProjectStructureEditor>
          </DialogContent>
        }

        <DialogActions>
          <Button onClick={handleEditModalClose} color="quSandBoxColor">
            Cancel
          </Button>
          {editProjectState == 1 && <Button onClick={editProjectStructure} color="quSandBoxColor">
            Next
          </Button>}
          {editProjectState == 2 && <Button onClick={handleEditProjectBrief} color="quSandBoxColor">
            Submit
          </Button>}
        </DialogActions>
      </Dialog>
      <Dialog
        open={modal}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <div style={{ fontSize: "larger" }}>Create New Experiment</div>
          <div>Project: {project.projectName}</div>
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            onChange={(event) => {
              setName(event.target.value);
            }}
            fullWidth
            required
          />
          <TextField
            margin="dense"
            id="version"
            label="Version"
            type="text"
            onChange={(event) => {
              setVersion(event.target.value);
            }}
            fullWidth
          />
          <TextField
            margin="dense"
            id="brief"
            label="Brief Description"
            type="text"
            onChange={(event) => {
              setBrief(event.target.value);
            }}
            fullWidth
            multiline={true}
            rows={3}
          />
          <TextField
            margin="dense"
            id="description"
            label="Detailed desciption for this experiment"
            type="text"
            onChange={(event) => {
              setDescription(event.target.value);
            }}
            fullWidth
            multiline={true}
            rows={3}
          />
          <TextField
            margin="dense"
            id="gistLink"
            label="Link to git gist for this experiment"
            type="text"
            onChange={(event) => {
              setGistLink(event.target.value);
            }}
            value={gistLink}
            fullWidth
          />
          <TextField
            margin="dense"
            id="colabLink"
            label="Link to colab for this experiment"
            type="text"
            onChange={(event) => {
              setColabLink(event.target.value);
            }}
            value={colabLink}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="quSandBoxColor">
            Cancel
          </Button>
          <Button onClick={handleCreateExperiment} color="quSandBoxColor">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      {expLoading || projectLoading ? (
        <Heading title={<CircularProgress />} textAlign="center" />
      ) : (
        <Switch>
          <Route exact path={path}>
            {/* <div>
            <Heading
              // title="My Experiments"
              textAlign="left"
              category={<b>My Experiments</b>}
            />
          </div> */}

            <div>
              <Heading
                title={project.projectName}
                textAlign="center"
                category={<span>{project.projectDescription}</span>}
              />
            </div>
            <GridContainer justify="center">
              {/* <GridItem xs={12} sm={10} md={10} lg={10}>
              <SearchBar
                value={searchValue}
                onChange={(newValue) => searchInputValue(newValue)}
                onRequestSearch={(newValue) => searchInputValue(newValue)}
              />
            </GridItem> */}
              <GridItem xs={12} sm={12} style={{ textAlign: "center" }}>
                {PERMISSION.indexOf(project.projectPermission) >=
                  PERMISSION.indexOf("write") && (
                    <Button
                      color="info"
                      onClick={createExperiment}
                      size="lg"
                      className={classes.buttons}
                    >
                      Create Experiment
                    </Button>
                  )}
                <Button
                  color="info"
                  size="lg"
                  disabled={!project.projectGistLink}
                  onClick={handleCodeModal}
                  className={classes.buttons}
                >
                  View Code
                </Button>
                {PERMISSION.indexOf(project.projectPermission) >=
                  PERMISSION.indexOf("own") && (
                    <Button
                      color="info"
                      onClick={editProject}
                      size="lg"
                      className={classes.buttons}
                    >
                      Edit Project
                    </Button>
                  )}
                {PERMISSION.indexOf(project.projectPermission) >=
                  PERMISSION.indexOf("audit") && (
                    <Button
                      color="info"
                      onClick={inviteUsers}
                      size="lg"
                      className={classes.buttons}
                    >
                      Invite Users
                    </Button>
                  )}
                {userType === 'admin' && !showOwn && (
                  <Button
                    color="info"
                    onClick={() => setShowOwn(!showOwn)}
                    size="lg"
                    className={classes.buttons}
                  >
                    Show Own
                  </Button>
                )}
                {userType === 'admin' && showOwn && (
                  <Button
                    color="info"
                    onClick={() => setShowOwn(!showOwn)}
                    size="lg"
                    className={classes.buttons}
                  >
                    Show All
                  </Button>
                )}
              </GridItem>
              <h3 className={classes.subHeadings}>
                <b>Experiments</b>
              </h3>
            </GridContainer>

            <GridContainer>
              {allExperiments.map((experiment, index) => (
                !(userType==='admin' && showOwn && (experiment.creator && experiment.creator !== user.username)) && <GridItem key={index} xs={12} sm={6} md={6} lg={4}>
                  <Link
                    to={{
                      pathname: url + "/" + experiment.SK.split("#")[1],
                      // +
                      // "/" +
                      // experiment.experimentVersion,
                      state: { project: project },
                    }}
                  >
                    <Card style={{ minHeight: "225px" }}>
                      <CardHeader stats icon>
                        <CardIcon color="quSandBox">
                          {experiment.experimentName}
                        </CardIcon>

                        <p className={classes.cardCategory}>
                          Version: {experiment.experimentVersion}
                        </p>
                        {userType === 'admin' && <p className={classes.cardCategory}>
                          Owner: {experiment.creator && experiment.creator !== user.username ? experiment.creator : 'Me'}
                        </p>}
                        <h3 className={classes.cardTitle}>
                          <small>
                            <img
                              src={images("./qusandbox_logo_v2.5.png")}
                              alt=""
                              className={classes.thumbnail}
                            />
                          </small>
                        </h3>
                      </CardHeader>
                      <CardBody
                        style={{ paddingTop: "0px", paddingBottom: "0px" }}
                      ></CardBody>
                      <CardFooter>
                        <h5>{experiment.experimentBrief + "  >"}</h5>
                      </CardFooter>
                      <CardFooter stats style={{ marginTop: "0px" }}>
                        {PERMISSION.indexOf(project.projectPermission) >=
                          PERMISSION.indexOf("write") && (
                            <FileCopyIcon
                              style={{ color: "#435966" }}
                              onClick={(e) =>
                                e.preventDefault(cloneAlert(experiment))
                              }
                            />
                          )}
                        {(PERMISSION.indexOf(project.projectPermission) >=
                          PERMISSION.indexOf("audit") ||
                          (PERMISSION.indexOf(project.projectPermission) ===
                            PERMISSION.indexOf("write") &&
                            experiment.creator === user.username)) && (
                            <DeleteIcon
                              style={{ color: "#435966" }}
                              onClick={(e) =>
                                e.preventDefault(deleteAlert(experiment))
                              }
                            />
                          )}
                      </CardFooter>
                    </Card>
                  </Link>
                </GridItem>
              ))}
            </GridContainer>
          </Route>
          <Route path={`${path}/:exp_id`}>
            <ExperimentDetails />
          </Route>
        </Switch>
      )}
    </ProjectContext.Provider>
  );
}
