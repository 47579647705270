import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ChatIcon from "@material-ui/icons/Chat";
import AssessmentIcon from "@material-ui/icons/Assessment";
import CreateIcon from "@material-ui/icons/Create";
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import QuizIcon from '@material-ui/icons/LiveHelp';

import NavPills from "components/NavPills/NavPills.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridItem from "components/Grid/GridItem.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import {
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from "@material-ui/core";

import ReportViewer from "views/Components/ReportViewer.js";
import Notes from "views/Components/Notes.js";
import Issues from "views/Components/Issues.js";
import ProjectStructureEditor from "views/Components/ProjectStructureEditor.js"
import ReactEmbedGist from "react-embed-gist";
import IconDict from "variables/IconDict.js"
import GeneralReportTemplate from "views/Components/GeneralReportTemplate.js"
import Assessment from "views/Components/Assessment.js"


import defaultProjectStructure from "variables/defaultProjectStructure.js"
import { NotificationContext, ExperimentContext, ProjectContext } from "views/Components/Context.js";
import styles from "assets/jss/material-dashboard-pro-react/views/experimentStyle.js";
const useStyles = makeStyles(styles);

export default function SummaryStage(props) {
    const classes = useStyles();

    const { showNotification } = React.useContext(NotificationContext);
    const {
        projectID: projectID,
        exp_id: exp_id,
        expData: [expData, setExpData],
        project: [project, setProject],
        user: user,
        stages: [stages, setStages],
        PERMISSION: PERMISSION,
        allNotes: [allNotes, setNotesHelper],
        allIssues: [allIssues, setIssuesHelper],
        experimentStructure: [expStructure, setExpStructure],
    } = React.useContext(ExperimentContext)
    const {
        projectTemplate: projectTemplate
    } = React.useContext(ProjectContext)

    const [experimentName, setExperimentName] = React.useState();
    const [experimentVersion, setExperimentVersion] = React.useState();
    const [experimentBrief, setExperimentBrief] = React.useState();
    const [expJSON, setExpJSON] = React.useState();
    const [experimentDescription, setExperimentDescription] = React.useState();
    const [experimentGistLink, setExperimentGistLink] = React.useState();
    const [experimentColabLink, setExperimentColabLink] = React.useState();
    const [experimentTemplate, setExperimentTemplate] = React.useState(projectTemplate ? projectTemplate : defaultProjectStructure)

    useEffect(() => {
        if (expData) {
            setExperimentBrief(expData.experimentBrief);
            setExperimentName(expData.experimentName);
            setExperimentVersion(expData.experimentVersion);
            setExpJSON(expData.json);
            setExperimentDescription(expData.experimentDescription);
            setExperimentGistLink(expData.experimentGistLink);
            setExperimentColabLink(expData.experimentColabLink);
            if (expData.experimentTemplate) {
                setExperimentTemplate(JSON.parse(expData.experimentTemplate));
            }
        }
    }, [expData])

    const [modalTag, setModalTag] = React.useState(0)
    const [codeModal, setCodeModal] = React.useState(false);

    const handleEditExperiment = () => {
        setModalTag(0)
        let token = user.signInUserSession.accessToken.jwtToken;
        fetch(process.env.REACT_APP_BACK_END_URL + `/experiment/${exp_id}`, {
            method: "PUT",
            mode: "cors",
            credentials: "omit",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
            body: JSON.stringify({
                experimentDescription: experimentDescription,
                experimentGistLink: experimentGistLink,
                experimentColabLink: experimentColabLink,
                json: expJSON,
                experimentName: experimentName,
                experimentBrief: experimentBrief,
                experimentVersion: experimentVersion,
                experimentTemplate: JSON.stringify(experimentTemplate)
            }),
        })
            .then(function (res) {
                return res.json();
            })
            .then(function (data) {
                let newExperiment = {
                    experimentDescription: experimentDescription,
                    experimentGistLink: experimentGistLink,
                    experimentColabLink: experimentColabLink,
                    json: expJSON,
                    experimentName: experimentName,
                    experimentBrief: experimentBrief,
                    experimentVersion: experimentVersion,
                    experimentTemplate: JSON.stringify(experimentTemplate)
                };
                setExpData({ ...expData, ...newExperiment });
                setExpStructure(experimentTemplate)
                showNotification("Experiment updated successfully.", "quSandBoxColor");
            })
            .catch((error) => {
                console.log(error);
                showNotification("Update failed.", "danger");
            });
    };

    let tmpTabs = [
        {
            tabButton: "Summary",
            tabIcon: ChatIcon,
            tabContent: (
                <Card className={classes.card}>
                    <CardHeader icon>
                        <CardIcon color="quSandBox">
                            <ChatIcon />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Summary</h4>
                    </CardHeader>

                    <CardBody>
                        <p className={classes.summaryTab}>
                            <b>Project Name: </b>
                            {project.projectName}
                        </p>

                        <p className={classes.summaryTab}>
                            <b>Project Description: </b>
                            {project.projectDescription}
                        </p>
                        <p className={classes.summaryTab}>
                            <b>Project Brief Description: </b>
                            {project.projectBrief}
                        </p>
                        <p className={classes.summaryTab}>
                            <b>Project ID: </b>
                            {projectID}
                        </p>
                        <p className={classes.summaryTab}>
                            <b>Project Version: </b>
                            {project.projectVersion}
                        </p>
                    </CardBody>
                    <CardBody>
                        <p className={classes.summaryTab}>
                            <b>Experiment Name: </b>
                            {expData.experimentName}
                        </p>
                        <p className={classes.summaryTab}>
                            <b>Experiment Description: </b>
                            {expData.experimentDescription}
                        </p>
                        <p className={classes.summaryTab}>
                            <b>Experiment Brief Description: </b>
                            {expData.experimentBrief}
                        </p>
                        <p className={classes.summaryTab}>
                            <b>Experiment ID: </b>
                            {exp_id}
                        </p>
                        <p className={classes.summaryTab}>
                            <b>Experiment Version: </b>
                            {expData.experimentVersion}
                        </p>
                    </CardBody>
                    <GridItem xs={12} style={{ textAlign: "center" }}>
                        {(PERMISSION.indexOf(project.projectPermission) >=
                            PERMISSION.indexOf("audit") ||
                            (PERMISSION.indexOf(project.projectPermission) ===
                                PERMISSION.indexOf("write") &&
                                expData.creator === user.username)) && (
                                <Button
                                    color="info"
                                    size="lg"
                                    onClick={() => setModalTag(1)}
                                    className={classes.buttonsTopSpace}
                                >
                                    Edit Experiment
                                </Button>
                            )}
                        <Button
                            color="info"
                            size="lg"
                            disabled={!expData.experimentGistLink}
                            onClick={() => setCodeModal(true)}
                            className={classes.buttonsTopSpace}
                        >
                            View Code
                        </Button>
                        <Button
                            color="info"
                            size="lg"
                            onClick={() =>
                                window.open(
                                    expData.experimentColabLink
                                )
                            }
                            disabled={!expData.experimentColabLink}
                            className={classes.buttonsTopSpace}
                        >
                            Run Code
                        </Button>
                        <Button
                            onClick={() =>
                                window.open(
                                    "https://modelrisk.qusandbox.com/#!/pipelines"
                                )
                            }
                            target="_blank"
                            color="info"
                            size="lg"
                            className={classes.buttonsTopSpace}
                        >
                            Run on Model Studio
                        </Button>
                    </GridItem>
                </Card>
            ),
        }
    ]
    tmpTabs.push({
        tabButton: "QuTrack",
        tabIcon: AssessmentIcon,
        tabContent: (
            <ReportViewer
                stageType="Summary"
                stageId=""
            />
        ),
    })
    tmpTabs.push(
        {
            tabButton: "Assessment",
            tabIcon: QuizIcon,
            tabContent: (
                // <GeneralReportTemplate
                //     templateId={
                //         "91c5f5da8d724ce5a3824b6000a498c1"
                //     }
                //     projectId={projectID}
                //     stageId={stages.hasOwnProperty('SummaryQuiz') ? stages['SummaryQuiz'].PK : ""}
                //     notes={allNotes.hasOwnProperty("SummaryQuiz")
                //         ? allNotes.SummaryQuiz
                //         : []}
                //     stageType="SummaryQuiz"
                // />
                <Assessment
                    templateId={
                        "2952f9aa75004504a5ef611441a812a8"
                    }
                ></Assessment>)

        }
    )
    if (props.config.reports) {
        tmpTabs.push(
            {
                tabButton: "Reports",
                tabIcon: IconDict['Reports'],
                tabContent: (<GeneralReportTemplate
                    templateId={
                        props.config.template
                            ? props.config.template
                            : ""
                    }
                    projectId={projectID}
                    stageId={stages.hasOwnProperty('Summary') ? stages['Summary'].PK : ""}
                    notes={allNotes.hasOwnProperty("Summary")
                        ? allNotes.Summary
                        : []}
                    stageType="Summary"
                />)
            }
        )
    }
    if (props.config.notes) {
        tmpTabs.push({
            tabButton: "Notes",
            tabIcon: CreateIcon,
            tabContent: (<Notes
                notes={allNotes.hasOwnProperty("Summary")
                    ? allNotes.Summary
                    : []}
                setNotes={setNotesHelper}
                stageType="Summary"
            />),
        })
    }
    if (props.config.issues) {
        tmpTabs.push(
            {
                tabButton: "Issues",
                tabIcon: LiveHelpIcon,
                tabContent: (
                    <Issues
                        issues={allIssues.hasOwnProperty("Summary")
                            ? allIssues.Summary
                            : []}
                        setIssues={setIssuesHelper}
                        stageType="Summary"
                    />
                ),
            })
    }

    const tabs = tmpTabs
    return (<>
        <Dialog
            open={codeModal}
            onClose={() => setCodeModal(false)}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            maxWidth="xl"
        >
            <DialogTitle id="form-dialog-title">Github Gist</DialogTitle>
            <DialogContent>
                {expData.experimentGistLink && (
                    <GridItem xs={12} sm={12} md={12}>
                        <ReactEmbedGist
                            contentClass={classes.gistclass}
                            gist={(expData.experimentGistLink && expData.experimentGistLink.includes("gist.github.com/")) ? expData.experimentGistLink.split("gist.github.com/")[1] : expData.experimentGistLink}
                        />
                    </GridItem>
                )}
            </DialogContent>
        </Dialog>
        <Dialog
            open={modalTag !== 0}
            onClose={() => setModalTag(0)}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">
                <div style={{ fontSize: "larger" }}>Edit Experiment</div>{" "}
                <div>Project: {project.projectName}</div>
                <div>Experiment: {expData.experimentName}</div>
            </DialogTitle>
            {modalTag == 1 && <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Name"
                    type="text"
                    onChange={(event) => {
                        setExperimentName(event.target.value);
                    }}
                    value={experimentName}
                    fullWidth
                    required
                />
                <TextField
                    margin="dense"
                    id="version"
                    label="Version"
                    type="text"
                    onChange={(event) => {
                        setExperimentVersion(event.target.value);
                    }}
                    value={experimentVersion}
                    fullWidth
                />
                <TextField
                    margin="dense"
                    id="brief"
                    label="Brief Description"
                    type="text"
                    onChange={(event) => {
                        setExperimentBrief(event.target.value);
                    }}
                    value={experimentBrief}
                    fullWidth
                    multiline={true}
                    rows={3}
                />
                <TextField
                    margin="dense"
                    id="description"
                    label="Detailed desciption for this experiment"
                    type="text"
                    onChange={(event) => {
                        setExperimentDescription(event.target.value);
                    }}
                    value={experimentDescription}
                    fullWidth
                    multiline={true}
                    rows={3}
                />
                <TextField
                    margin="dense"
                    id="gistLink"
                    label="Link to git gist for this experiment"
                    type="text"
                    onChange={(event) => {
                        setExperimentGistLink(event.target.value);
                    }}
                    fullWidth
                    value={experimentGistLink}
                />
                <TextField
                    margin="dense"
                    id="colabLink"
                    label="Link to colab for this experiment"
                    type="text"
                    onChange={(event) => {
                        setExperimentColabLink(event.target.value);
                    }}
                    fullWidth
                    value={experimentColabLink}
                />
                <CustomInput
                    labelText="Custom key json"
                    id="custom_key_json"
                    formControlProps={{
                        fullWidth: true,
                    }}
                    inputProps={{
                        multiline: true,
                        rows: 5,
                        onChange: (event) => {
                            setExpJSON(event.target.value);
                        },
                        value: expJSON,
                    }}
                />
            </DialogContent>}
            {
                modalTag == 2 && <DialogContent>
                    <ProjectStructureEditor
                        structure={experimentTemplate}
                        setStructure={setExperimentTemplate}
                        projectId={projectID}
                    ></ProjectStructureEditor>
                </DialogContent>
            }
            <DialogActions>
                <Button onClick={() => setModalTag(0)} color="quSandBoxColor">
                    Cancel
                </Button>
                {modalTag == 1 && <Button onClick={() => setModalTag(2)} color="quSandBoxColor">
                    Next
                </Button>
                }
                {modalTag == 2 && <Button onClick={handleEditExperiment} color="quSandBoxColor">
                    Submit
                </Button>
                }
            </DialogActions>
        </Dialog>
        <NavPills
            color="quSandBoxColor"
            alignCenter
            tabs={tabs}
        /></>)
}