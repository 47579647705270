import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import NavPills from "components/NavPills/NavPills.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import { Card } from "@material-ui/core";
import TrackChangesIcon from '@material-ui/icons/TrackChanges';

import IconDict from "variables/IconDict.js";
import Notes from "views/Components/Notes.js";
import Issues from "views/Components/Issues.js";
import GeneralReportTemplate from "views/Components/GeneralReportTemplate.js";

import {
  NotificationContext,
  ExperimentContext,
} from "views/Components/Context.js";
import styles from "assets/jss/material-dashboard-pro-react/views/experimentStyle.js";
const useStyles = makeStyles(styles);

export default function MonitoringStage(props) {
  const classes = useStyles();

  const {
    allNotes: [allNotes, setNotesHelper],
    allIssues: [allIssues, setIssuesHelper],
    stages: [stages, setStages],
    projectID: projectID,
  } = React.useContext(ExperimentContext);

  let tmpTabs = [
    {
      tabButton: "Monitoring",
      tabContent: (
        <Card className={classes.card}>
          <CardHeader icon>
            <CardIcon color="quSandBox">
              <IconDict.Monitoring />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Monitoring</h4>
          </CardHeader>
          <CardBody>
            <GridContainer justify="center">
              {stages.hasOwnProperty("Monitoring") &&
              stages["Monitoring"].hasOwnProperty("Link") ? (
                <iframe
                  title="monitoring"
                  id="monitoring"
                  src={stages["Monitoring"].Link}
                  width="100%"
                  frameBorder="0"
                  height={`${stages["Monitoring"].IframeHeight}px`}
                ></iframe>
              ) : (
                <Button
                  color="info"
                  size="lg"
                  href={`https://grafana.quantuniversity.com`}
                  target="_blank"
                >
                  Browse Tools
                </Button>
              )}
            </GridContainer>
          </CardBody>
        </Card>
      ),
      tabIcon: IconDict["Monitoring"],
    },
  ];
  if (projectID === "f1541de1cd174681a8fa4b5a40239005") {
    tmpTabs.push({
      tabButton: "Tracking",
      tabIcon: TrackChangesIcon,
      tabContent: (
        <Card className={classes.card}>
          <CardHeader icon>
            <CardIcon color="quSandBox">
              <TrackChangesIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Tracking</h4>
          </CardHeader>
          <CardBody>
            <GridContainer justify="center">
              <Button
                  color="info"
                  size="lg"
                  href={`https://academy.qusandbox.com/demos/mlflow`}
                  target="_blank"
                >
                  Browse Tools
                </Button>
            </GridContainer>
          </CardBody>
        </Card>
      ),
    });
  }

  if (props.config.reports) {
    tmpTabs.push({
      tabButton: "Reports",
      tabIcon: IconDict["Reports"],
      tabContent: (
        <GeneralReportTemplate
          templateId={props.config.template ? props.config.template : ""}
          projectId={projectID}
          stageId={
            stages.hasOwnProperty("Monitoring") ? stages["Monitoring"].PK : ""
          }
          notes={
            allNotes.hasOwnProperty("Monitoring") ? allNotes.Monitoring : []
          }
          stageType="Monitoring"
        />
      ),
    });
  }
  if (props.config.notes) {
    tmpTabs.push({
      tabButton: "Notes",
      tabIcon: IconDict["Notes"],
      tabContent: (
        <Notes
          notes={
            allNotes.hasOwnProperty("Monitoring") ? allNotes.Monitoring : []
          }
          setNotes={setNotesHelper}
          stageType="Monitoring"
        />
      ),
    });
  }
  if (props.config.issues) {
    tmpTabs.push({
      tabButton: "Issues",
      tabIcon: IconDict["Issues"],
      tabContent: (
        <Issues
          issues={
            allIssues.hasOwnProperty("Monitoring") ? allIssues.Monitoring : []
          }
          setIssues={setIssuesHelper}
          stageType="Monitoring"
        />
      ),
    });
  }

  const tabs = tmpTabs;
  return <NavPills color="quSandBoxColor" alignCenter tabs={tabs} />;
}
